import "../App.css";
import TimeLineAboutUs from "../Components/AboutUsComponent/TimeLine";
import TogetherAboutUs from "../Components/AboutUsComponent/Together";
import Footer from "../Components/Footer";
import Navbar from "../Components/Navbar";
import { useEffect } from "react";
const AboutUsPage = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return(
       <>
        <section>
            <Navbar />
            <TimeLineAboutUs />
        </section>
        <section>
            <TogetherAboutUs />
        </section>
        <section>
            <Footer />
        </section>
        
        
        </>

    )
}

export default AboutUsPage