// CookieConsent.js
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import gifCookie from '../Images/gifCookie.gif'
import '../App.css'


const CookieConsent = () => {

    const { t } = useTranslation();

    const [isVisible, setIsVisible] = useState(false);
    const [hasConsented, setHasConsented] = useState(false);

    useEffect(() => {
        const consent = localStorage.getItem("cookieConsent");
        if (consent === null) {
            setIsVisible(true);
        } else {
            setHasConsented(consent === "true");
        }
    }, []);

    const handleAccept = () => {
        localStorage.setItem("cookieConsent", "true");
        setIsVisible(false);
        setHasConsented(true);
    };

    const handleReject = () => {
        localStorage.setItem("cookieConsent", "false");
        setIsVisible(false);
        setHasConsented(false);
       
    };


    return (
        isVisible && (
            <div className="cookie-consent-modal cookie-consent-backdrop">
                <div class="cookie-consent-banner">
                    <div className="cookie-banner-svg">
                        <img src={gifCookie} alt="" />
                    </div>   
                    <div class="cookie-consent-banner__description">{t('cookies.desc')}</div>
                     <div className="cookie-button-wrapper">
                        <button onClick={handleAccept} class="cookie-consent-button">
                                {t('cookies.accept')}
                        </button>
                        <button onClick={handleReject} class="cookie-consent-button">
                            {t('cookies.reject')}
                        </button>
                        
                    </div>
                </div>
               

            </div>
        )
    );
};

export default CookieConsent;
