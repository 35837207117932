import TimeLineLiberary from "./TimeLineLiberary"
import { useTranslation } from "react-i18next";

const TimeLineAboutUs = () => {
    const { t } = useTranslation();
    return(
        <div className="timeline_container">
            <div className="timeline_wrapper">
                <h1>{t('about.heading')}</h1>
                <p>{t('about.subheading')}</p>
                <TimeLineLiberary />
            </div>
        </div>
    )
}

export default TimeLineAboutUs