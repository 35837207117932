import React, { useEffect } from "react";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Home from "./Pages/HomePage";
import AboutUsPage from "./Pages/AboutUs";
import FAQ from "./Pages/FAQ";
import PrivacyPolicy from "./Pages/PrivacyPolicy";
import ContactUs from "./Pages/ContactUs";
import Sapcotent from "./Pages/Sapcotent";

const AppRoutes = () => {
 const { i18n } = useTranslation();
 const location = useLocation();

 const languageRoutes = {
  en: "en",
  sv: "sv",
  de: "de",
 };

 useEffect(() => {
  const pathLanguage = location.pathname.split("/")[1];

  if (languageRoutes[pathLanguage] && pathLanguage !== i18n.language) {
   i18n.changeLanguage(pathLanguage);
  }
 }, [location, i18n]);

 const currentLanguage = i18n.language;

 return (
  <Routes>
   <Route path={`/${languageRoutes[currentLanguage]}/`} element={<Home />} />
   <Route
    path={`/${languageRoutes[currentLanguage]}/about-us`}
    element={<AboutUsPage />}
   />
   <Route path={`/${languageRoutes[currentLanguage]}/faq`} element={<FAQ />} />
   <Route
    path={`/${languageRoutes[currentLanguage]}/privacy-policy`}
    element={<PrivacyPolicy />}
   />
   <Route
    path={`/${languageRoutes[currentLanguage]}/contact-us`}
    element={<ContactUs />}
   />
   <Route
    path={`/${languageRoutes[currentLanguage]}/sapcontent`}
    element={<Sapcotent />}
   />
   <Route
    path="/"
    element={<Navigate to={`/${languageRoutes[currentLanguage]}/`} />}
   />
  </Routes>
 );
};

const App = () => {
 return <AppRoutes />;
};

export default App;
