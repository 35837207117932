import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'
import { enTranslation } from '../translation/en/global'
import { svTranslation } from '../translation/sv/global'
import { deTranslation } from '../translation/de/global'

i18n
 .use(LanguageDetector)
 .use(initReactI18next)
 .init({
  debug: true,
  lng: "en",
  fallbackLng: "en",
  detection: {
   order: ["localStorage", "cookie", "navigator"],
   caches: ["localStorage"],
  },
  resources: {
   en: { translation: enTranslation },
   sv: { translation: svTranslation },
   de: { translation: deTranslation },
  },
 });
 