import { useTranslation } from "react-i18next";

const TogetherAboutUs = () => {
    const { t } = useTranslation();
    return(
        <div className="together_container">
            <div className="together_wrapper">
                <div className="together_content">
                    <h1>{t('about.mission.heading')}</h1>
                    <p>{t('about.mission.p')}</p>
                </div>

                <div className="together_image">
                    <img alt="Owision Mission" src="https://evampsaanga.com/wp-content/uploads/2020/06/dart-dartboard-game-precision-242494.jpg" />
                </div>
            </div>
        </div>
    )
}

export default TogetherAboutUs