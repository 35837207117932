import { useTranslation } from "react-i18next";

const ContactUsSection = () => {

    const { t } = useTranslation();

    function handleMailTo() {
        const name = document.getElementById('name').value;
        const email = document.getElementById('email').value;
        const phone = document.getElementById('phone').value;
        const message = document.getElementById('message').value;

        const mailtoLink = `mailto:info@owision.com?subject=Contact Form Submission&body=Name: ${name}%0DEmail: ${email}%0DPhone: ${phone}%0DMessage: ${message}`;

        window.location.href = mailtoLink;
    }
    return(
        <div className="mapAdress">
        <div className="map">
        <iframe title="Contact" src="https://www.google.com/maps/d/u/0/embed?mid=1N9KkYTbD5QpO31MBSvsYIo7CFJLVxyA&ehbc=2E312F&noprof=1" width="100%" height="580"></iframe>
        </div>
        <div className="contact_container">
            <div className="contact_wrapper">
                    <h1>{t('contactUs.form.heading')}</h1>
                    <p className="heading_contact">{t('contactUs.form.subheading')}</p>

                <div className="form_video">
                    <div className="form_wrapper">
                        <div className="name_email">
                            <div className="name_div" style={{width:"45%"}}>
                                    <p>{t('contactUs.form.name.title')}</p>
                                    <input type="text" id="name" placeholder={t('contactUs.form.name.placeholder')} />
                            </div>
                            <div className="name_div" style={{width:"45%"}}>
                                    <p>{t('contactUs.form.email.title')}</p>
                                    <input type="text" id="email" placeholder={t('contactUs.form.email.placeholder')} />
                            </div>
                        </div>
                        <div className="name_email">
                            <div className="name_div" style={{width:"100%"}}>
                                    <p>{t('contactUs.form.phone.title')}</p>
                                    <input type="text" id="phone" placeholder={t('contactUs.form.phone.placeholder')} />
                            </div>
                        </div>
                        <div className="name_email">
                            <div className="name_div" style={{width:"100%"}}>
                                    <p>{t('contactUs.form.msg.title')}</p>
                                    <textarea className="custom-placeholder" id="message" placeholder={t('contactUs.form.msg.placeholder')} />
                            </div>
                        </div>
                        <div className="send_button">
                                <button onClick={handleMailTo}>{t('contactUs.form.button')}</button>
                        </div>
                    </div>
                    <div className="video_div">
                      <div className="video_wrapper">
                      <video autoPlay loop controls={false} muted preload="metadata">
                            <source type="video/mp4" src="https://essentials.pixfort.com/digital-agency/wp-content/uploads/sites/55/2022/01/google-search.mp4"></source>
                        </video>
                      </div>
                    </div>
                </div>
            </div>
        </div>
        </div>
    )
}

export default ContactUsSection;