import { useEffect, useState } from "react";
import Logo from "../Images/owisionlogo.png";
import { useTranslation } from "react-i18next";
import LanguageSelector from "./LanguageSelector";
import { Link, useLocation } from "react-router-dom"; 

const Navbar = () => {
    const { t, i18n } = useTranslation();
    const location = useLocation(); 
    const [openMenu, setOpenMenu] = useState(false);
    const [activeLink, setActiveLink] = useState("/");

   
    useEffect(() => {
        setActiveLink(location.pathname);
    }, [location]);

    
    const currentLanguage = i18n.language;

    return (
        <div className="navbar_container">
            <div className="navbar_wrapper">
                <div className="navbar_logo">
                    <Link to={`/${currentLanguage}`}> {/* Link to the base path with language */}
                        <img alt="Owision AB Logo" src={Logo} />
                    </Link>
                </div>
                <div className="menu_toggle">
                    <div className="menu_link">
                        <Link to={`/${currentLanguage}/`} className={activeLink === `/${currentLanguage}/` ? "active_blue" : ""}>
                            {t('navbar.home')}
                        </Link>
                        <Link to={`/${currentLanguage}/about-us`} className={activeLink === `/${currentLanguage}/about-us` ? "active_blue" : ""}>
                            {t('navbar.about')}
                        </Link>
                        <Link to={`/${currentLanguage}/contact-us`} className={activeLink === `/${currentLanguage}/contact-us` ? "active_blue" : ""}>
                            {t('navbar.contact')}
                        </Link>
                        <Link to={`/${currentLanguage}/faq`} className={activeLink === `/${currentLanguage}/faq` ? "active_blue" : ""}>
                            {t('navbar.faq')}
                        </Link>
                        <LanguageSelector />
                    </div>
                    <svg
                        onClick={() => setOpenMenu(true)}
                        xmlns="http://www.w3.org/2000/svg"
                        width="30"
                        height="30"
                        fill="#303F7A"
                        className="bi bi-list"
                        viewBox="0 0 16 16"
                    >
                        <path
                            fillRule="evenodd"
                            d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5"
                        />
                    </svg>
                </div>

                {openMenu && (
                    <div className="full_screen_menu">
                        <div className="navbar_inner_wrapper" style={{ display: "flex", justifyContent: "flex-end" }}>
                            <svg
                                onClick={() => setOpenMenu(false)}
                                xmlns="http://www.w3.org/2000/svg"
                                width="20"
                                height="20"
                                fill="black"
                                className="bi bi-x-lg"
                                viewBox="0 0 16 16"
                            >
                                <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z" />
                            </svg>
                        </div>
                        <div className="menu_link_mobile">
                            <Link to={`/${currentLanguage}/`}>{t('navbar.home')}</Link>
                            <Link to={`/${currentLanguage}/about-us`}>{t('navbar.about')}</Link>
                            <Link to={`/${currentLanguage}/contact-us`}>{t('navbar.contact')}</Link>
                            <Link to={`/${currentLanguage}/faq`}>{t('navbar.faq')}</Link>
                            <LanguageSelector />
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default Navbar;
