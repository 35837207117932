import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const SecondSection = () => {
    const { t, i18n } = useTranslation();
    const currentLanguage = i18n.language;
    return(
        <div className="section_container">
            <div className="section_wrapper">
                <div className="section_img">
                    <img src="/img/hero_section2.png" alt="hii"/>
                </div>
                <div className="section_content">
                    <div className="section_content_wrapper">
                        <h1>{t('secondSection.heading')}</h1>
                        <p>{t('secondSection.description')}</p>
                        <button>
                            <Link to={`/${currentLanguage}/about-us`}> 
                                {t('secondSection.buttonLabel')}
                            </Link>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SecondSection;