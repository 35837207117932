import "../App.css";
import CountUp from 'react-countup';
import { useTranslation } from "react-i18next";


const HeroSection = () => {
    
    const { t } = useTranslation();
    return(
        <div className="home_container mobile_height">
            <div className="home_wrapper">
                <div className="home_content">
                    <h1>{t('heroSection.heading')}<br /> {t('heroSection.subheading')}</h1>
                    <p>{t('heroSection.description')}</p>
                    <div className="home_button">
                        <button><a rel="noreferrer" href="https://calendly.com/owision" target="_blank">{t('heroSection.buttonLabel')}</a></button>
                    </div>
                </div>
                {/* <div className="home_image">
                    <img alt="Owision Home Page" className="relative_image" src={Home}/>
                    <img alt="Owision Home Page" className="none_relative" src="https://tecnologia.vamtam.com/wp-content/uploads/2023/03/GettyImages-618762080-1.jpg"/>
                    <img alt="Owision Home Page" className="none_relative" src={Home2}/>

                </div> */}
            </div>

            <div className="home_count">
                <div className="employe_count">
                    <h1><CountUp end={50} />+</h1>
                    <p>{t('heroSection.count.employee')}</p>
                </div>
                <div className="employe_count">
                    <h1><CountUp end={10}/>+</h1>
                    <p>{t('heroSection.count.clients.c1')} <br />{t('heroSection.count.clients.c2')}</p>
                </div>
                <div className="employe_count">
                    <h1><CountUp end={20} />+</h1>
                    <p>{t('heroSection.count.projects.p1')} <br />{t('heroSection.count.projects.p2')}</p>
                </div>

                <div className="employe_count">
                    <h1><CountUp end={12} />+</h1>
                    <p>{t('heroSection.count.experience.e1')} <br />{t('heroSection.count.experience.e2')}</p>
                </div>
            </div>
        </div>
    )
}

export default HeroSection