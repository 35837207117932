import { useEffect, useState } from "react";
import Footer from "../Components/Footer";
import Navbar from "../Components/Navbar";

import { useTranslation } from "react-i18next";

const FAQ = () => {
    const { t } = useTranslation();
    const faqData = t('faq', { returnObjects: true });

    const [answerShow , setAnswerShow] = useState();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    const handleClick = (index) => {
        if (answerShow === index) {
            setAnswerShow(null);
        } else {
            setAnswerShow(index);
        }
    };

    return( 
       <>
        <section>
            <Navbar />
        </section>
        <section>
            <div className="faq_container">
                <div className="faq_wrapper">
                        <h1>{t('faqHeading') }</h1>
                    <div className="faq_div_wrapper">
                        {
                            faqData?.map((item,index)=>{
                                return(
                                    <div className="question_div" onClick={()=>handleClick(index)} key={index+1}>
                                       <div className="question_data">
                                        <p>{item.question}</p>
                                            <div className="question_plus">
                                                {
                                                    answerShow === index ?
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="white" class="bi bi-dash" viewBox="0 0 16 16">
                                                        <path d="M4 8a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7A.5.5 0 0 1 4 8"/>
                                                    </svg>: 
                                                     <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="white" class="bi bi-plus" viewBox="0 0 16 16">
                                                        <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4"/>
                                                    </svg>

                                                }
                                               
                                               
                                            </div>
                                       </div>
                                       {

                                        answerShow === index &&
                                         <div className="answer_div">
                                            <p>{item.answer}</p>
                                        </div>
                                       }

                                    </div>
                                )
                            })
                        }
                    </div>

                </div>

            </div>
        </section>
        <section>
            <Footer />
        </section>
       </>
    )
}

export default FAQ;