import { useTranslation } from "react-i18next";

const ThirdSection = () => {

    const { t } = useTranslation();
    return(
        <div className="section_container">
            <div className="section_wrapper third_wrapper">
                <div className="third_img_wrapper">
                    <img src={t('thirdSection.bannerImage')} alt="serviceImage" />
                    <div className="service_icons">
                        <div className="service_first_div">
                            <img src="/img/service1.svg" alt="service1" />
                            <p>{t('thirdSection.one')}</p>
                        </div>
                        <div className="service_first_div">
                            <img src="/img/service3.svg" alt="service3 " />
                            <p>{t('thirdSection.two')}</p>
                        </div>
                        <div className="service_first_div">
                            <img src="/img/service2.svg"  alt="service2"/>
                            <p>{t('thirdSection.three')}</p>
                        </div>
                        <div className="service_first_div">
                            <img src="/img/service4.svg" alt="service4" />
                            <p>{t('thirdSection.four')}</p>
                        </div>
                        <div className="service_first_div">
                            <img src="/img/service5.svg" alt="service5" />
                            <p>{t('thirdSection.five')}</p>
                        </div>
                    </div>
                </div>
             
            </div>
        </div>
    )
}

export default ThirdSection;