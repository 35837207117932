import { useEffect } from "react";
import Footer from "../Components/Footer";
import Navbar from "../Components/Navbar";
import { useTranslation } from "react-i18next";

const PrivacyPolicy =()=>{

  const { t } = useTranslation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
    return(
      <>
      <section>
        <Navbar />
      </section>
      <section>
        <div className="tnc_container">
            <div className="tnc_wraper">
              <h1>{t('privacy.heading') }</h1>
              <p> {t('privacy.sub')} </p>
            </div>
            <div className="policy_text">
              <p>{t('privacy.p1')}
                <a target="_blank" rel="noreferrer" href="https://owision.com/"> https://owision.com/</a><br/>
              </p><h5>{t('privacy.p2')}</h5>
              <br /><h4>{t('privacy.cookies.heading')}</h4><br/>
              <p>{t('privacy.cookies.desc')}<br/>
              </p><br /><h4>{t('privacy.pixels.heading')}</h4><br/>
              <p>{t('privacy.pixels.desc')}
                <br /></p><br /><h4>{t('privacy.thirdParty.heading')}</h4><br/>
              <p>{t('privacy.thirdParty.desc')}
                <br /></p><br /><h4>{t('privacy.embedded.heading')}</h4><br/>
              <p>{t('privacy.embedded.desc')}
                <br /></p><br /><h4>{t('privacy.analytics.heading')}</h4><br/>
              <p>{t('privacy.analytics.desc')}&nbsp;<a target="_blank" rel="noreferrer" href="https://web.archive.org/web/20220126142746/https://www.google.com/policies/privacy/">{t('privacy.analytics.link')}</a>.
                <br /></p><br /><p>{t('privacy.p3')}</p><br/>
              <p>{t('privacy.p4')}
                <br /></p><br /><p>{t('privacy.p5')}</p></div>
        </div>
      </section>
      <section>
        <Footer />
      </section>
      </>
    )
}

export default PrivacyPolicy;